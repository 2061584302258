<template>
  <div class="panel panel--profile panel-blue-border">
    <div title="Pending" v-if="condition === 'pending'" class="pending-condition pb-1">
      <PendingIcon />
    </div>
    <div class="panel-heading pb-1">
      <h3>{{ $t("languages") }}</h3>
      <a v-if="editable" @click.prevent="openEditorModal" href="#"><EditIcon2 class="btn-edit" /></a>
    </div>
    <div class="panel-body">
        <div v-if="languages" v-html="languages"></div>
        <p v-else class="text-primary-light">{{ $t("no-languages-yet") }}</p>
    </div>
  </div>
</template>

<script>

import EditIcon2 from '../icons/EditIcon2';
import PendingIcon from '../icons/credit-management/PendingIcon';

export default {
  name: 'resume-languages-panel',
  i18nOptions: { namespaces: 'resume-panel' },
  components: {
    EditIcon2,
    PendingIcon,
  },
  props: ['languages', 'condition', 'editable'],
  methods: {
    openEditorModal() {
      this.$store.commit('editor/content', this.languages);
      this.$store.commit('editor/field', 'languages');
      this.$store.commit('editor/title', this.$t('languages'));
      this.$store.commit('editor/isEditorModalOpen', true);
    },
  },
};
</script>
