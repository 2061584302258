<template>
  <div class="panel panel--profile">
    <div title="Pending" v-if="condition === 'pending'" class="pending-condition pb-1">
      <PendingIcon />
    </div>
    <div class="panel-heading pb-1">
      <h3 class="relative">{{ $t("bio") }} <div v-if="!notPublic" v-tooltip.hover="$t('tooltip-bio')" class="small-info-circle">i</div></h3>
      <a v-if="editable" @click.prevent="openEditorModal" href="#"><EditIcon2 class="btn-edit" /></a>
    </div>
    <div class="panel-body">
        <div v-if="bio" v-html="bio"></div>
        <p v-else class="text-primary-light">{{ $t("no-bio-yet") }}</p>
    </div>
  </div>
</template>

<script>
import { tooltip } from 'uiv';
import EditIcon2 from '../icons/EditIcon2';
import PendingIcon from '../icons/credit-management/PendingIcon';

export default {
  name: 'resume-bio-panel',
  i18nOptions: { namespaces: 'resume-panel' },
  components: {
    EditIcon2,
    PendingIcon,
  },
  directives: {
    tooltip,
  },
  props: ['bio', 'condition', 'editable', 'notPublic'],
  methods: {
    openEditorModal() {
      this.$store.commit('editor/content', this.bio);
      this.$store.commit('editor/field', 'bio');
      this.$store.commit('editor/title', this.$t('bio'));
      this.$store.commit('editor/isEditorModalOpen', true);
    },
  },
};
</script>
