<template>
  <div class="panel panel--profile panel--profile__work-experience">
    <div class="panel-heading pb-1">
      <h3>{{ $t("work-history") }}</h3>
    </div>
    <div class="panel-body">
        <div v-if="workExperience && workExperience.length > 0">
          <draggable
            class="list-group list-group--work-experience"
            :class="{'stop-dragging' : !editable}"
            tag="ul"
            v-model="myList"
            v-bind="dragOptions"
            :disabled="!editable"
            @start="isDragging = true"
            @end="isDragging = false"
          >
            <transition-group type="transition" name="flip-list">
              <li
                class="list-group-item"
                v-for="(item, index) in myList"
                :key="item.order_position"
              >
                <div class="work-item">
                  <div class="heading-row">
                    <h5>{{ $t('position') }} {{ index + 1 }}</h5>
                    <div class="actions">
                      <a v-if="editable && list && (index != 0)" @click.prevent="changeOrderUp(item, index)" href="#"><i class="material-icons" aria-hidden="true">arrow_circle_up</i></a>
                      <a v-if="editable && list && (index != list.length-1)" @click.prevent="changeOrderDown(item, index)" href="#"><i class="material-icons" aria-hidden="true">arrow_circle_down</i></a>
                      <a v-if="editable && list" @click.prevent="openWorkExperienceModal(index)" href="#"><EditIcon2 class="btn-edit" /></a>
                      <a v-if="editable && list" @click.prevent="confirmDeleteItem(index)" href="#"><i class="material-icons" aria-hidden="true">delete_outline</i></a>
                    </div>
                  </div>
                  <div class="body-content">
                    <div class="pairs">
                      <strong>{{ $t('position-name') }}:</strong>
                      <p>{{ item.position }}</p>
                    </div>
                    <div class="pairs">
                      <strong>{{ $t('period') }}:</strong>
                      <p v-if="!item.to">{{ $t('from') }} {{ item.from | date('MMM YYYY', 'YYYY-MM') }} {{ $t('to') }} {{ $t('date')}}</p>
                      <p v-else>{{ $t('from') }} {{ item.from | date('MMM YYYY', 'YYYY-MM') }} {{ $t('to') }} {{ item.to | date('MMM YYYY', 'YYYY-MM')}}</p>
                    </div>
                    <div v-if="editable" class="pairs">
                      <strong>{{ $t('employer') }}:</strong>
                      <p>{{ item.employer }}</p>
                    </div>
                    <div class="pairs">
                      <strong>{{ $t('details') }}:</strong>
                      <div v-html="item.details"></div>
                    </div>
                  </div>
                </div>
              </li>
            </transition-group>
          </draggable>
        </div>
        <p v-else class="text-primary-light">{{ $t("no-work-history-yet") }}</p>
        <div class="row" v-if="editable">
          <div class="col-xs-12">
            <div class="client-role-form__buttons">
              <button
                v-if="show"
                type="button"
                @click="saveResume"
                class="btn btn-primary-light btn-modal mr-2"
              >
                {{ $t("save-changes") }}
              </button>
              <button
                type="button"
                @click="openWorkExperienceModal"
                class="btn btn-primary-light-outline btn-modal"
              >
                {{ $t("add-new-position") }}
              </button>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import EditIcon2 from '../icons/EditIcon2';
import { formatDate } from '../../utils/date.filter';
import Notifications from '../../services/notifications.service';
import ModalService from '../../services/modal.service';

export default {
  name: 'resume-work-experience-panel',
  i18nOptions: { namespaces: 'resume-panel' },
  components: {
    draggable,
    EditIcon2,
  },
  data() {
    return {
      list: this.workExperience,
      show: false,
    };
  },
  filters: {
    date: formatDate,
  },
  props: ['workExperience', 'editable'],
  watch: {
    // eslint-disable-next-line func-names
    workExperience: function (newVal, oldVal) {
      if (newVal) {
        this.list = newVal;
      }
      // console.log('workExperience changed: ', newVal, ' | was: ', oldVal);
    },
  },
  computed: {
    myList: {
      get() {
        return this.list;
      },
      set(value) {
        const updatedValue = value.map((item, index) => {
          item.order_position = index + 1;
          return item;
        });
        this.list = updatedValue;
        this.show = true;
        return this.$store.commit('profile/resumeProps', { work_experiences: this.list });
      },
    },
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },
  methods: {
    openWorkExperienceModal(index = null) {
      if (index != null) {
        this.$store.commit('workExperience/resumeWorkExperienceIndex', index);
        if (this.workExperience[index]) {
          this.$store.commit('workExperience/formProps', this.workExperience[index]);
        }
      }
      this.$store.commit('workExperience/isResumeWorkExperienceModalOpen', true);
      this.show = true;
    },
    changeOrderDown(item, index) {
      // save clicked item in temporary variable
      const temp = item;
      temp.order_position += 1;
      this.list[index + 1].order_position -= 1;
      // move the following item to the clicked element
      this.list[index] = this.list[index + 1];
      // move clicked item to destination
      this.list[index + 1] = temp;
      this.show = true;
    },
    changeOrderUp(item, index) {
      // save clicked item in temporary variable
      const temp = item;
      temp.order_position -= 1;
      this.list[index - 1].order_position += 1;
      // move the following item to the clicked element
      this.list[index] = this.list[index - 1];
      // move clicked item to destination
      this.list[index - 1] = temp;
      this.show = true;
    },
    saveResume() {
      this.$store.dispatch('profile/saveResume')
        .then((res) => {
          this.success();
        })
        .catch((err) => {
          if (err.hasOwnProperty('response')) {
            // console.log('error');
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    removeItem(position) {
      this.show = true;
      this.list.splice(position, 1);
      this.$store.commit('profile/resumeProps', { work_experiences: this.list });
    },
    confirmDeleteItem(position) {
      const content = this.$t('are-you-sure', { label: this.list[position].position });
      ModalService.confirm({
        content: content,
        cancelType: 'link',
        okText: this.$t('yes'),
        customClass: 'modal-confirm',
        cancelText: this.$t('cancel'),
      })
        .then(() => {
          this.removeItem(position);
        })
        .catch(() => {
        });
    },
    success(res) {
      this.show = false;
      this.$store.dispatch('profile/getResume');
      if (typeof res.message !== 'undefined') {
        Notifications.notify('success', res.message);
      }
    },
  },
};
</script>
