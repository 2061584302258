<template>
  <div class="panel panel--cover">
    <img v-if="coverImage" :src="coverImage" alt="Cover image">
    <img v-else src="/img/cover.jpg" alt="Cover image">
    <div class="panel-heading pb-1">
      <a v-if="editable" @click.prevent="openCoverImageModal" href="#"><CoverEditIcon class="btn-edit" /></a>
      <h4 v-if="!hide || (hide && $route.name === 'neoworqer-profile' && profile.name)" class="text-white">{{profile.name}}</h4>
      <h5 class="text-white">{{profile.userActiveResume.job_title}}</h5>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CoverEditIcon from '../icons/CoverEditIcon';

export default {
  name: 'resume-cover-panel',
  i18nOptions: { namespaces: 'resume-panel' },
  components: {
    CoverEditIcon,
  },
  props: ['profile', 'coverImage', 'editable'],
  computed: {
    hide() {
      return this.$route.name !== 'resume' && this.$route.name !== 'overview' && this.$route.name !== 'overview-notification';
    },
  },
  methods: {
    openCoverImageModal() {
      this.$store.dispatch('coverImages/getAllCoverImages').then(() => {
        this.$store.commit('coverImages/isCoverImagesModalOpen', true);
      });
    },
  },
};
</script>
