<template>
  <div class="panel panel--profile panel--profile__skills">
    <div class="panel-heading pb-1">
      <h3>{{ $t("skills") }}</h3>
      <a v-if="editable" @click.prevent="openEditorModal" href="#"><EditIcon2 class="btn-edit" /></a>
    </div>
    <div class="panel-body">
        <div v-if="resumeSkills && resumeSkills.length > 0">
          <span :key="skill.id" v-for="skill in resumeSkills">
            <span class="skill" :class="{'skill--blue' : skill.active_for_neoworqer}">{{ skill.name }}</span>
          </span>
        </div>
        <p v-else class="text-primary-light">{{ $t("no-skills-yet") }}</p>
    </div>
  </div>
</template>

<script>

import EditIcon2 from '../icons/EditIcon2';

export default {
  name: 'resume-skills-panel',
  i18nOptions: { namespaces: 'resume-panel' },
  components: {
    EditIcon2,
  },
  props: ['resumeSkills', 'editable'],
  methods: {
    openEditorModal() {
      this.$store.commit('skills/isSkillsModalOpen', true);
    },
  },
};
</script>
